<template>
  <vue-highcharts type="chart" :options="chartData"></vue-highcharts>
</template>
<script>
import VueHighcharts from "vue3-highcharts";
import { computed } from "vue";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "WindRoseHighChartV2",
  components: { VueHighcharts },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["rgb(102, 197, 204)", "#AA4643"],
    },
  },
  setup(props) {
    const chartData = computed(() => {
      return {
        title: {
          text: "",
        },
        chart: {
          // fontFamily: "Helvetica, Arial, sans-serif",
          height: 400,
          polar: true,
          type: "column",
        },
        // colors: props.colors,
        pane: {
          // background: [
          //   {
          //     shape: "circle",
          //     borderColor: "#cccccc",
          //     borderWidth: 1,
          //   },
          // ],
          // size: "80%",
          innerSize: "20%",
          startAngle: 0,
          // endAngle: 330,
        },
        xAxis: {
          tickmarkPlacement: "on",
          categories: [
            "-100%",
            "-80%",
            "-60%",
            "-40%",
            "-20%",
            "0%",
            "20%",
            "40%",
            "60%",
            "80%",
            "100%",
          ],
          // crosshair: true,
          labels: {
            style: {
              fontFamily: "Helvetica, Arial, sans-serif",
              color: "rgb(161, 165, 183)",
              fontSize: "12px",
            },
          },
        },
        series: props.series,
        yAxis: {
          min: 0,
          endOnTick: false,
          labels: {
            enabled: false,
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value.value);
            },
            style: {
              fontFamily: "Helvetica, Arial, sans-serif",
              color: "rgb(161, 165, 183)",
              fontSize: "12px",
            },
          },
          reversedStacks: false,
        },
        tooltip: {
          animation: true,
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          shared: true,
          borderRadius: 12,
          useHTML: true,
          style: {
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: "12px",
          },
          formatter: function () {
            return this.points.reduce(function (s, point) {
              return (
                s +
                "<br/>" +
                "<span style='padding-left: 2px; font-size: 16px; color:" +
                props.colors[0] +
                "'>●</span> " +
                point.series.name +
                ": " +
                "<b style='padding-left: 4px;'>" +
                formatNumberIntoHumanizeForm(point.y) +
                "</b>"
              );
            }, "<b>" + this.x + "</b>");
          },
        },
        plotOptions: {
          series: {
            stacking: "normal",
            shadow: true,
            groupPadding: 0,
            showInLegend: true,
            // pointPlacement: "on",
          },
          // column: {
          //   animation: true,
          //   colorByPoint: true,
          //   colors: [
          //     "#AA4643",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //     "rgb(102, 197, 204)",
          //   ],
          // },
        },
      };
    });
    return { chartData };
  },
};
</script>
<style lang="scss">
.highcharts-credits {
  display: none !important;
}

//.highcharts-legend-item {
//  .highcharts-point {
//    fill: rgb(102, 197, 204);
//  }
//}

//.highcharts-xaxis-labels {
//  color: rgb(161, 165, 183) !important;
//  cursor: default !important;
//  font-size: 12px !important;
//  font-family: Helvetica, Arial, sans-serif !important;
//  fill: rgb(161, 165, 183) !important;
//}

/*.highcharts-yaxis-labels {*/
/*  color: rgb(161, 165, 183) !important;*/
/*  cursor: default !important;*/
/*  font-size: 12px !important;*/
/*  font-family: Helvetica, Arial, sans-serif !important;*/
/*  fill: rgb(161, 165, 183) !important;*/
/*}*/
</style>

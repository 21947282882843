<template>
  <div class="spotify-growth mt-2">
    <div
      v-for="(el, index) in data"
      :key="index"
      :id="el.id"
      :class="el.margin ? '' : 'margin-bottom-12'"
    >
      <CardToolbar
        :show-toolbar-buttons="false"
        description=""
        :title="el.cardTitle"
        :fontStyle="true"
        :margin="el.margin"
        :padding="false"
        :padding-left="true"
        :shadow-class="true"
      >
        <template v-slot:body>
          <el-row :gutter="12">
            <el-col
              :lg="8"
              :md="8"
              :sm="12"
              v-for="(record, i) in el.data"
              :key="i"
            >
              <WindRoseHighChartV2 :series="[record]" />
            </el-col>
          </el-row>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import CardToolbar from "@/common/components/CardToolbar.vue";
import WindRoseHighChartV2 from "@/common/components/WindRoseHighChartV2.vue";

export default {
  name: "SpotifyGrowthV2",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardToolbar,
    WindRoseHighChartV2,
  },
};
</script>

<template>
  <div class="score-main-content">
    <sub-top-nav-bar-v2
      :current-active-tab="currentActiveTab"
      main-id="score_menu_list"
      sub-id="score_sub_menu_list"
      :tabs-list="tabMenuList"
      :have-sub-list="true"
      current-active-sub-list="spotify_listeners_growth"
      :sub-list="tabSubList"
      :scroll-to-element-margin-main="125"
      @changeSubList="changeSubList"
    />
    <div class="mt-8">
      <div v-for="(record, i) in records" :key="i" :id="record.mainId">
        <div class="artist-content-heading">{{ record.mainHeading }}</div>
        <SpotifyGrowthsV2 :data="record.data" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import SubTopNavBarV2 from "@/common/components/SubTopNavBarV2.vue";
import SpotifyGrowthsV2 from "@/scores/components/SpotifyGrowthsV2.vue";
import { getPlatformColor } from "@/utils/staticHelper";

export default {
  name: "ScoresDetailV2",
  components: {
    SubTopNavBarV2,
    SpotifyGrowthsV2,
  },
  setup() {
    const currentActiveTab = ref("#spotify_radial_growth");
    const data = ref([
      10000, 10000, 10000, 10000, 11000, 10000, 10000, 10000, 10000, 10000,
      10000,
    ]);
    const records = ref([
      {
        mainHeading: "Spotify",
        mainId: "spotify_radial_growth",
        data: [
          {
            id: "spotify_listeners_growth",
            margin: false,
            cardTitle: "Spotify Listener Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
            ],
          },
          {
            id: "spotify_followers_growth",
            margin: false,
            cardTitle: "Spotify Followers Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
            ],
          },
          {
            id: "spotify_popularity_growth",
            margin: false,
            cardTitle: "Spotify Popularity Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
            ],
          },
          {
            id: "spotify_totals_growth",
            margin: true,
            cardTitle: "Spotify Total Growths",
            data: [
              {
                name: "Listeners Total",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "Followers Total",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
              {
                name: "Popularity Total",
                data: data.value,
                color: getPlatformColor("spotify"),
              },
            ],
          },
        ],
      },
      {
        mainHeading: "Playlists",
        mainId: "playlist_radial_growth",
        data: [
          {
            id: "playlist_count_growth",
            margin: false,
            cardTitle: "Playlists Count Growth",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "24 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "36 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
            ],
          },
          {
            id: "playlist_subscribers_growth",
            margin: false,
            cardTitle: "Playlists Subscribers Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "24 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "36 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
            ],
          },
          {
            id: "playlist_count_editorial_growth",
            margin: false,
            cardTitle: "Playlists Count Editorial Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "24 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "36 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
            ],
          },
          {
            id: "playlist_subscribers_editorial_growth",
            margin: false,
            cardTitle: "Playlists Subscribers Editorial Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "24 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "36 Months",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
            ],
          },
          {
            id: "total_playlist_growth",
            margin: true,
            cardTitle: "Total Playlists Reach Growths",
            data: [
              {
                name: "Playlists Reach Total",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
              {
                name: "Playlist Reach Editorial Total",
                data: data.value,
                color: "rgb(0, 158, 247)",
              },
            ],
          },
        ],
      },
      {
        mainHeading: "Social Media",
        mainId: "social_media_growth",
        data: [
          {
            id: "instagram_followers_growth",
            margin: false,
            cardTitle: "Instagram Follower Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("instagram"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("instagram"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("instagram"),
              },
            ],
          },
          {
            id: "tiktok_followers_growth",
            margin: false,
            cardTitle: "TikTok Follower Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
            ],
          },
          {
            id: "tiktok_likes_growth",
            margin: false,
            cardTitle: "Tiktok Likes Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
            ],
          },
          {
            id: "facebook_likes_growth",
            margin: false,
            cardTitle: "Facebook Likes Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("facebook"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("facebook"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("facebook"),
              },
            ],
          },
          {
            id: "melon_fans_growth",
            margin: false,
            cardTitle: "Melon Fans Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("melon"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("melon"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("melon"),
              },
            ],
          },
          {
            id: "line_artist_likes",
            margin: false,
            cardTitle: "Line Likes Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("line"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("line"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("line"),
              },
            ],
          },
          {
            id: "deezer_fans",
            margin: true,
            cardTitle: "Deezer Fans Growths",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("deezer"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("deezer"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("deezer"),
              },
            ],
          },
          {
            id: "social_medial_total_growths",
            margin: true,
            cardTitle: "Social Total Growths",
            data: [
              {
                name: "Instagram Followers Total",
                data: data.value,
                color: getPlatformColor("instagram"),
              },
              {
                name: "Tiktok Followers Total",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "Tiktok Likes Total",
                data: data.value,
                color: getPlatformColor("tiktok"),
              },
              {
                name: "Facebook Talks Total",
                data: data.value,
                color: getPlatformColor("facebook"),
              },
              {
                name: "Twitter Retweets Total",
                data: data.value,
                color: getPlatformColor("twitter"),
              },
            ],
          },
        ],
      },
      {
        mainHeading: "Youtube",
        mainId: "youtube_growths",
        data: [
          {
            id: "youtube_channel_views",
            margin: false,
            cardTitle: "Youtube Channel Views",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
            ],
          },
          {
            id: "youtube_channel_subscribers",
            margin: false,
            cardTitle: "Youtube Channel Subscribers",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
            ],
          },
          {
            id: "youtube_artist_monthly_views",
            margin: false,
            cardTitle: "Youtube Artist Monthly Views",
            data: [
              {
                name: "12 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "24 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "36 Months",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
            ],
          },
          {
            id: "youtube_growth_total",
            margin: true,
            cardTitle: "Youtube Total Growths",
            data: [
              {
                name: "Youtube Channel Videos Total",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "Youtube Channel Views Total",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
              {
                name: "Youtube Channel Subscribers Total",
                data: data.value,
                color: getPlatformColor("youtube"),
              },
            ],
          },
        ],
      },
    ]);
    const tabMenuList = ref([
      {
        id: "spotify_radial_growth",
        name: "Spotify",
        icon: "/media/svg/social-logos/spotify.svg",
        alt: "spotify-growth",
      },
      {
        id: "playlist_radial_growth",
        name: "Playlists",
        icon: "/media/svg/playlist.svg",
        alt: "playlists-growth",
      },
      {
        id: "social_media_growth",
        name: "Social Media",
        icon: "/media/svg/social.svg",
        alt: "social-growth",
      },
      {
        id: "youtube_growths",
        name: "Youtube",
        icon: "/media/svg/social-logos/youtube.svg",
        alt: "youtube-growth",
      },
    ]);
    const tabSubList = ref([
      {
        id: "spotify_listeners_growth",
        tabName: "Listeners",
      },
      {
        id: "spotify_followers_growth",
        tabName: "Followers",
      },
      {
        id: "spotify_popularity_growth",
        tabName: "Popularity",
      },
      {
        id: "spotify_totals_growth",
        tabName: "Total",
      },
    ]);
    const changeSubList = (tabName) => {
      currentActiveTab.value = tabName;
      if (currentActiveTab.value === "#spotify_radial_growth") {
        tabSubList.value = [
          {
            id: "spotify_listeners_growth",
            tabName: "Listeners",
          },
          {
            id: "spotify_followers_growth",
            tabName: "Followers",
          },
          {
            id: "spotify_popularity_growth",
            tabName: "Popularity",
          },
          {
            id: "spotify_totals_growth",
            tabName: "Total",
          },
        ];
      } else if (currentActiveTab.value === "#playlist_radial_growth") {
        tabSubList.value = [
          {
            id: "playlist_count_growth",
            tabName: "Playlists Count",
          },
          {
            id: "playlist_subscribers_growth",
            tabName: "Playlists Subscribers",
          },
          {
            id: "playlist_count_editorial_growth",
            tabName: "Playlists Count Editorial",
          },
          {
            id: "playlist_subscribers_editorial_growth",
            tabName: "Playlists Subscribers Editorial",
          },
          {
            id: "total_playlist_growth",
            tabName: "Total",
          },
        ];
      } else if (currentActiveTab.value === "#social_media_growth") {
        tabSubList.value = [
          {
            id: "instagram_followers_growth",
            tabName: "Instagram",
          },
          {
            id: "tiktok_followers_growth",
            tabName: "Tiktok Followers",
          },
          {
            id: "tiktok_likes_growth",
            tabName: "Tiktok Likes",
          },
          {
            id: "facebook_likes_growth",
            tabName: "Facebook",
          },
          {
            id: "melon_fans_growth",
            tabName: "Melon",
          },
          {
            id: "line_artist_likes",
            tabName: "Line",
          },
          {
            id: "deezer_fans",
            tabName: "Deezer",
          },
        ];
      } else if (currentActiveTab.value === "#youtube_growths") {
        tabSubList.value = [
          {
            id: "youtube_channel_views",
            tabName: "Channel Views",
          },
          {
            id: "youtube_channel_subscribers",
            tabName: "Channel Subscribers",
          },
          {
            id: "youtube_artist_monthly_views",
            tabName: "Artist Monthly Views",
          },
          {
            id: "youtube_growth_total",
            tabName: "Total",
          },
        ];
      }
    };
    return {
      currentActiveTab,
      tabMenuList,
      tabSubList,
      records,
      changeSubList,
    };
  },
};
</script>
